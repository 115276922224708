import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/Bi_dashboard_login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  const formData = new FormData();
  formData.append('user_name', email);
  formData.append('password', password);

  console.log("user email: " + email);
  console.log("user password: " + password);
  console.log("user URL: " + LOGIN_URL);
  var output: any = [];


  axios({
    method: 'post',
    url: LOGIN_URL,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data', // Set the Content-Type header to multipart/form-data
    },
  }).then(function (response) {
    console.log(Object.keys(response.data.widget_access));
    console.log(response.data.token);
    localStorage.setItem('authTokenLoop', response.data.token);
    localStorage.setItem('user', response.data.user.user_name);
    localStorage.setItem('WidgetAccess', JSON.stringify(Object.keys(response.data.widget_access)));
    localStorage.setItem('suppliers', JSON.stringify(response.data.suppliers));

    if (response.data.success) {
      window.location.href = '/dashboard'
    } else {
      alert("Invalid username or password");
    }

    output = response;
  })
    .catch(function (error) {
      console.log(error);
    });

  return output;
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
