import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import DashbaordWrapper from '../pages/dashboard/DashboardWrapper'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashbaordWrapper />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
