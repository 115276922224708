import React, { Component } from 'react';
import Chart from "react-apexcharts";
import axios from 'axios'
import { Circles } from 'react-loader-spinner'

export class Chart1 extends Component {
    constructor() {
        super()
        this.state = {
            options: {
                chart: {
                    type: "bar"
                },
                xaxis: {
                    categories: []
                }
            },
            series: [],
            isLoaded: false,
            filterDate: ''
        };
        this.componentDidMount = this.componentDidMount.bind(this)
        this.filterChnage = this.filterChnage.bind(this)
        this.updateChart = this.updateChart.bind(this)
    }

    updateState(name, data) {
        const newSeries = {
            name: name,
            data: data
        };

        this.setState((prevState) => ({
            series: [...prevState.series, newSeries]
        }));
    }

    updateLoader(stateValue) {
        if (this.state.isLoaded === true) {
            this.setState({ isLoaded: false })
        } else {
            this.setState({ isLoaded: stateValue })
        }
    }

    updateCategories(newCategories) {
        this.setState((prevState) => ({
            options: {
                ...prevState.options, // Preserve other properties in the options object
                xaxis: {
                    ...prevState.options.xaxis, // Preserve other properties in xaxis
                    categories: newCategories, // Update categories with the new data
                },
            },
        }));
    }

    filterChnage(event) {
        console.log(event.target.value)
        this.setState({ filterDate: event.target.value })
    }

    getData(formData) {
        const API_URL = process.env.REACT_APP_API_URL
        const token = localStorage.getItem("authTokenLoop");
        const dataUrl = API_URL + "/Bi_dashboard_ota"

        const time_slot = [];
        const arranged_count = [];
        const not_arranged_count = [];

        axios({
            method: 'post',
            url: dataUrl,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data', // Set the Content-Type header to multipart/form-data
                'Authorization': 'Bearer ' + token,
            },
        }).then((response) => {
            const reportData = response.data.data.report_data;
            reportData.forEach(item => {
                const timeSlotStart = item.time_slot[0];
                const timeSlotEnd = item.time_slot[1];
                const formattedTimeSlot = `${timeSlotStart} - ${timeSlotEnd}`;
                time_slot.push(formattedTimeSlot);
                arranged_count.push(item.arranged_count);
                not_arranged_count.push(item.not_arranged_count);

            });

            this.setState({ series: [] })
            this.setState({ options: { chart: { type: "bar" }, xaxis: { categories: [] } } })

            this.updateCategories(time_slot)
            this.updateState("Arranged", arranged_count)
            this.updateState("Not arranged", not_arranged_count)
            this.updateLoader(true)

        }).catch(function (error) {
            console.log(error);
        });
    }

    updateChart() {
        const newDate = this.state.filterDate;
        const formData = new FormData();
        formData.append('filter_date', newDate);
        this.setState({ isLoaded: false })
        this.getData(formData);
    }

    componentDidMount() {
        const formData = new FormData();
        formData.append('filter_date', '2023-07-25');
        this.setState({ filterDate: '2023-07-25' })
        this.getData(formData);
    }

    render() {
        return (
            <div className={`card col-5 m-4`}>
                <div className='card-header border-0 pt-5'>
                    <div className='row'>
                        <div className='col-10'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-3 mb-1'>On Time Arrangement</span>
                            </h3>
                        </div>
                        <div className='col-1'>
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'>
                                <i className='fas fa-filter mx-1'></i> Filter
                            </button>

                            <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
                                <div className='px-7 py-5'>
                                    <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                </div>

                                <div className='separator border-gray-200'></div>

                                <div className='px-7 py-5'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bold'>Filter Date:</label>
                                        <div>
                                            <input type="Date" name='date' value={this.state.filterDate} className='form-control' onChange={this.filterChnage} />
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-end'>
                                        <button
                                            type='reset'
                                            className='btn btn-sm btn-white btn-active-light-primary me-2'
                                            data-kt-menu-dismiss='true'>
                                            Reset
                                        </button>

                                        <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true' onClick={this.updateChart}>
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className='card-body'>
                    <div id='loader' className={this.state.isLoaded == true && 'd-none'} style={{ height: "100%", width: "150%" }}>
                        <Circles
                            height="100%"
                            width="40%"
                            color="#FF870D"
                            ariaLabel="circles-loading"
                            wrapperStyle={{ padding: "15% 0", marginLeft: "16%" }}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>

                    <div id='loader' className={!this.state.isLoaded == true && 'd-none'} style={{ height: "100%" }}>
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            width="100%"
                            height="150%"
                        />
                    </div>
                </div>
            </div>
        )
    }
}