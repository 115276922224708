import React, { Component } from 'react';
import Chart from "react-apexcharts";
import axios from 'axios'
import { Circles } from 'react-loader-spinner'

export class PCRCchart extends Component {
    constructor() {
        super()
        this.state = {
            options: {
                chart: {
                    type: "pie"
                },
                labels: ['Issued Carpets', 'Returned Carpets'],
                legend: { position: 'bottom' },

            },
            series: [],
            isLoaded: false,
            filterDateTo: '',
            filterDateFrom: ''
        };

        this.componentDidMount = this.componentDidMount.bind(this)
        this.filterChange = this.filterChange.bind(this)
        this.updateChart = this.updateChart.bind(this)
    }

    updateState(data) {
        this.setState((prevState) => ({
            series: data
        }));
    }

    filterChange(key, event) {
        console.log(key + " " + event.target.value)
        if (key == 'to') {
            this.setState({ filterDateTo: event.target.value })
        } else if (key == 'from') {
            this.setState({ filterDateFrom: event.target.value })
        }
    }

    updateLoader(stateValue) {
        if (this.state.isLoaded === true) {
            this.setState({ isLoaded: false })
        } else {
            this.setState({ isLoaded: stateValue })
        }
    }

    getData(formData) {
        const API_URL = process.env.REACT_APP_API_URL
        const token = localStorage.getItem("authTokenLoop");
        const dataUrl = API_URL + "/Bi_dashboard_pcr_c"

        axios({
            method: 'post',
            url: dataUrl,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
        }).then((response) => {
            console.log(response.data.data);
            const dataArray = [Math.round(response.data.data.issued_carpets), Math.round(response.data.data.returned_carpets)]
            console.log(dataArray)

            this.setState({ series: [] })
            this.setState({ options: { chart: { type: "pie" }, labels: ['Issued Carpets', 'Returned Carpets'], legend: { position: 'bottom' } } })

            this.updateState(dataArray)
            this.updateLoader(true)

        }).catch(function (error) {
            console.log(error);
        });
    }

    updateChart() {
        const filterDateTo = this.state.filterDateTo;
        const filterDateFrom = this.state.filterDateFrom;

        const formData = new FormData();
        formData.append('filter_date_from', filterDateFrom);
        formData.append('filter_date_to', filterDateTo);
        this.setState({ isLoaded: false })
        this.getData(formData);
    }

    componentDidMount() {
        const formData = new FormData();
        formData.append('filter_date_from', '2023-06-22');
        formData.append('filter_date_to', '2023-06-23');
        this.setState({ filterDateFrom: '2023-06-22' })
        this.setState({ filterDateTo: '2023-06-23' })
        this.getData(formData);
    }

    render() {
        return (
            <div className={`card col-5 m-5`} style={{ width: '46%' }}>
                <div className='card-header border-0 pt-4'>
                    <div className='row'>
                        <div className='col-10'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-3 mb-1'>Carpets Returns Report</span>
                            </h3>
                        </div>
                        <div className='col-2'>
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'>
                                <i className='fas fa-filter mx-1'></i> Filter
                            </button>

                            <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
                                <div className='px-7 py-5'>
                                    <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                </div>

                                <div className='separator border-gray-200'></div>

                                <div className='px-7 py-5'>

                                    <div className='mb-10'>
                                        <label className='form-label fw-bold'>Filter From Date:</label>
                                        <div>
                                            <input type="Date" name='date' value={this.state.filterDateFrom}
                                                onChange={(event) => this.filterChange('from', event)}
                                                className='form-control' />
                                        </div>
                                    </div>

                                    <div className='mb-10'>
                                        <label className='form-label fw-bold'>Filter To Date:</label>
                                        <div>
                                            <input type="Date" name='date' value={this.state.filterDateTo}
                                                onChange={(event) => this.filterChange('to', event)}
                                                className='form-control' />
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-end'>
                                        <button
                                            type='reset'
                                            className='btn btn-sm btn-white btn-active-light-primary me-2'
                                            data-kt-menu-dismiss='true'>
                                            Reset
                                        </button>

                                        <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true' onClick={this.updateChart}>
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div id='loader' className={this.state.isLoaded == true && 'd-none'} style={{ height: "100%", width: "150%" }}>
                        <Circles
                            height="100%"
                            width="40%"
                            color="#EA1C26"
                            ariaLabel="circles-loading"
                            wrapperStyle={{ padding: "15% 0", marginLeft: "16%" }}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>

                    <div id='loader' className={!this.state.isLoaded == true && 'd-none'} style={{ height: "150%" }}>
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="pie"
                            width="100%"
                            height="150%"
                        />
                    </div>
                </div>
            </div >
        )
    }
}