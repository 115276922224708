import React, { Component } from 'react';
import Chart from "react-apexcharts";
import axios from 'axios'
import { Circles } from 'react-loader-spinner'

export class VUTchart extends Component {
    constructor() {
        super()
        this.state = {
            options: {
                chart: {
                    type: "bar"
                },
                xaxis: {
                    categories: []
                }
            },
            series: [],
            isLoaded: false,
            filterSupplier: '',
            filterDateTo: '',
            filterDateFrom: '',
            suppliers: JSON.parse(localStorage.getItem('suppliers'))

        };
        this.componentDidMount = this.componentDidMount.bind(this)
        this.filterChange = this.filterChange.bind(this)
        this.updateChart = this.updateChart.bind(this)
    }

    updateState(name, data) {
        const newSeries = {
            name: name,
            data: data
        };

        this.setState((prevState) => ({
            series: [...prevState.series, newSeries]
        }));
    }

    filterChange(key, event) {
        console.log(key + " " + event.target.value)
        if (key == 'to') {
            this.setState({ filterDateTo: event.target.value })
        } else if (key == 'from') {
            this.setState({ filterDateFrom: event.target.value })
        } else if (key == 'filterSupplier') {
            this.setState({ filterSupplier: event.target.value })
        }

    }

    updateCategories(newCategories) {
        this.setState((prevState) => ({
            options: {
                ...prevState.options, // Preserve other properties in the options object
                xaxis: {
                    ...prevState.options.xaxis, // Preserve other properties in xaxis
                    categories: newCategories, // Update categories with the new data
                },
            },
        }));
    }

    findVehNoById(vehicles, veh_id) {
        const vehicle = vehicles.find((v) => v.veh_id === veh_id);
        return vehicle ? vehicle.veh_no : null;
    }

    getData(formData) {
        const API_URL = process.env.REACT_APP_API_URL
        const token = localStorage.getItem("authTokenLoop");
        const dataUrl = API_URL + "/Bi_dashboard_vut"
        const veh_no_data = [];
        const shp_avg_uitl_data = [];

        axios({
            method: 'post',
            url: dataUrl,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
        }).then((response) => {
            const reportData = response.data.data.vehicle_utilizations;
            const vehicleData = response.data.data.vehicles;

            reportData.forEach(item => {
                const shp_avg_uitl = Number(item.shp_avg_uitl).toFixed(1);
                shp_avg_uitl_data.push(shp_avg_uitl);
                var veh_no = this.findVehNoById(vehicleData, item.veh_id)
                veh_no_data.push(veh_no);
            });

            this.setState({ series: [] })
            this.setState({ options: { chart: {type: "bar" }, xaxis: { categories: [] } } })

            this.updateState("Average Utilization", shp_avg_uitl_data)
            this.updateCategories(veh_no_data)
            this.updateLoader(true)

        }).catch(function (error) {
            console.log(error);
        });

    }

    updateLoader(stateValue) {
        if (this.state.isLoaded === true) {
            this.setState({ isLoaded: false })
        } else {
            this.setState({ isLoaded: stateValue })
        }
    }

    updateChart() {
        const newToDate = this.state.filterDateTo;
        const newFromDate = this.state.filterDateFrom;
        const filterSupplier = this.state.filterSupplier;


        const formData = new FormData();
        formData.append('filter_date_from', newFromDate);
        formData.append('filter_date_to', newToDate);
        formData.append('supplier_id', filterSupplier);
        this.setState({ isLoaded: false })
        this.getData(formData);
    }

    componentDidMount() {

        const formData = new FormData();
        formData.append('filter_date_from', '2023-07-01');
        formData.append('filter_date_to', '2023-07-15');
        formData.append('supplier_id', '7');
        this.getData(formData);

    }

    render() {
        return (
            <div className={`card col-12 m-5`} style={{ width: '97%' }}>
                <div className='card-header border-0 pt-5'>
                    <div className='row'>
                        <div className='col-10'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-3 mb-1'>Vehicle Utilization</span>
                            </h3>
                        </div>
                        <div className='col-2'>
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'>
                                <i className='fas fa-filter mx-1'></i> Filter
                            </button>

                            <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
                                <div className='px-7 py-5'>
                                    <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                </div>

                                <div className='separator border-gray-200'></div>

                                <div className='px-7 py-5'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bold'>Filter From Date:</label>
                                        <div>
                                            <input type="Date" name='date' value={this.state.filterDateFrom}
                                                onChange={(event) => this.filterChange('from', event)}
                                                className='form-control' />
                                        </div>
                                    </div>

                                    <div className='mb-10'>
                                        <label className='form-label fw-bold'>Filter To Date:</label>
                                        <div>
                                            <input type="Date" name='date' value={this.state.filterDateTo}
                                                onChange={(event) => this.filterChange('to', event)}
                                                className='form-control' />
                                        </div>
                                    </div>

                                    <div className='mb-10'>
                                        <label className='form-label fw-bold'>Filter Supplier:</label>
                                        <div>

                                            <select name='suppliers' id='suppliers' className='form-control'
                                                value={this.state.filterSupplier}
                                                onChange={(event) => this.filterChange('filterSupplier', event)}>
                                                {this.state.suppliers.map((supplier) => (
                                                    <option key={supplier.sup_id} value={supplier.sup_id}>
                                                        {supplier.sup_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-end'>
                                        <button
                                            type='reset'
                                            className='btn btn-sm btn-white btn-active-light-primary me-2'
                                            data-kt-menu-dismiss='true'>
                                            Reset
                                        </button>

                                        <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true' onClick={this.updateChart}>
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='card-body'>

                    <div id='loader' className={this.state.isLoaded == true && 'd-none'} style={{ height: "50%", width: "50%" }}>
                        <Circles
                            height="100%"
                            width="40%"
                            color="#EA1C26"
                            ariaLabel="circles-loading"
                            wrapperStyle={{ padding: "15% 0", marginLeft: "16%" }}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>

                    <div id='loader' className={!this.state.isLoaded == true && 'd-none'} style={{ height: "100%" }}>
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            width="100%"
                            height="200%"
                        />
                    </div>
                </div>
            </div>
        )
    }
}