import { Component } from 'react';
import { Chart1 } from '../../components/chart1';
import { PieChart } from '../../components/Piechart';
import { VUTchart } from '../../components/VUTchart';
import { TripCostchart } from '../../components/TripCostchart';
import { DSSchart } from '../../components/DSSchart';
import { TTCBCchart } from '../../components/TTCBCchart';
import { TTCPCchart } from '../../components/TTCPCchart';
import { LIOchart } from '../../components/LIOchart';
import { VLLchart } from '../../components/VLLchart';
import { PCRPchart } from '../../components/PCRPchart';
import { PCRCchart } from '../../components/PCRCchart';
import { RKMchart } from '../../components/RKMchart';

export class DashboardPage extends Component<any, any> {
  constructor(props: any) {
    super(props)
    var accessObject: string = localStorage.getItem('WidgetAccess') || "[]";
    this.state = {
      widgetAccess: JSON.parse(accessObject),
    };
  }

  checkAccess(name: string) {
    var accessList = this.state.widgetAccess;
    console.log(accessList)

    if (accessList.includes(name)) {
      return true
    } else {
      return false
    }
  }

  render() {

    return (
      <div className='row' style={{ width: '118%', marginLeft: '-8%' }}>
        <div className='col-12'>
          <div className='row'>
            {this.checkAccess('OTA') ? (
              <Chart1 />
            ) : (<div></div>)}

            {this.checkAccess('OTD') ? (
              <PieChart />
            ) : (<div></div>)}

            {this.checkAccess('VUT') ? (
              <VUTchart />
            ) : (<div></div>)}

            {this.checkAccess('TCS') ? (
              <TripCostchart />
            ) : (<div></div>)}

            {this.checkAccess('DSS') ? (
              <DSSchart />
            ) : (<div></div>)}

            {this.checkAccess('TTC') ? (
              <TTCBCchart />
            ) : (<div></div>)}

            {this.checkAccess('TTC') ? (
              <TTCPCchart />
            ) : (<div></div>)}

            {this.checkAccess('VLL') ? (
              <VLLchart />
            ) : (<div></div>)}

            {this.checkAccess('LIO') ? (
              <LIOchart />
            ) : (<div></div>)}

            {this.checkAccess('PCR') ? (
              <PCRPchart />
            ) : (<div></div>)}

            {this.checkAccess('PCR') ? (
              <PCRCchart />
            ) : (<div></div>)}

            {this.checkAccess('RKM') ? (
              <RKMchart />
            ) : (<div></div>)}


            {/* <PieChart /> */}
          </div>
        </div>
      </div>
    )
  }
}

function DashbaordWrapper() {
  return (
    <div className="container">
      <DashboardPage />
    </div >
  );
}

export default DashbaordWrapper;
